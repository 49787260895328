<template>
    <div>
        <div v-if="displayMessages">
            <v-alert
            outlined
            type="success"
            text
            v-for="(message, index) in messages" :key="index"
            dense
            >
            {{ message }}
            </v-alert>
        </div>

        <div v-if="errorMessages">
            <v-alert
            outlined
            type="error"
            icon="mdi-information"
            text
            v-for="(message, index) in errorMessages" :key="index"
            dense
            >
            {{ message }}
            </v-alert>
        </div>
    </div>
</template>

<script>
export default {
    name: 'form-submit-messages',
    props: ['messages', 'errorMessages'],

    data: () => ({

    }),

    computed: {
        displayMessages() {
            if(this.messages == undefined) {
                return false;
            }

            if(this.messages !== undefined && this.messages.length == 0) {
                return false;
            }

            return true;
            
        },

        displayErrorMessages() {
            if(this.errorMessages == undefined) {
                return false;
            }

            if(this.errorMessages !== undefined && this.errorMessages.length == 0) {
                return false;
            }

            return true;
        }
    },

    created() {

    },

    mounted() {
        //window.window.console.log("FormSubmitMessages.vue");
        //window.console.log(this.messages);

    }
}
</script>

<style>

</style>